/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Layout, SEO } from '../components/common';
import { AlignCenter, BlogWrapper, PageWrapper, TagContainer, Tag } from '../styles';
import { Header } from '../components/theme';

import './github-markdown.css';

const Template = function ({ data }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <SEO />
      <Header />
      <PageWrapper as={BlogWrapper}>
        <div className="markdown-body">
          <h1>{frontmatter.title}</h1>
          <AlignCenter>
            <GatsbyImage image={frontmatter.hero.childImageSharp.gatsbyImageData} alt="" />
          </AlignCenter>
          <TagContainer>
            {frontmatter.tags ? frontmatter.tags.map((tag) => <Tag key={tag}>{tag}</Tag>) : ''}
          </TagContainer>
          <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        hero {
          absolutePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        path
        title
        tags
      }
    }
  }
`;

export default Template;
